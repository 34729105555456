/* Deafult Margin & Padding */
/*-- Margin Top --*/
.mt_5 {
    margin-top: 5px;
}
.mt_10 {
    margin-top: 10px;
}
.mt_15 {
    margin-top: 15px;
}
.mt_20 {
    margin-top: 20px;
}
.mt_25 {
    margin-top: 25px;
}
.mt_30 {
    margin-top: 30px;
}
.mt_35 {
    margin-top: 35px;
}
.mt_40 {
    margin-top: 40px;
}
.mt_45 {
    margin-top: 45px;
}
.mt_50 {
    margin-top: 50px;
}
.mt_55 {
    margin-top: 55px;
}
.mt_60 {
    margin-top: 60px;
}
.mt_65 {
    margin-top: 65px;
}
.mt_70 {
    margin-top: 70px;
}
.mt_75 {
    margin-top: 75px;
}
.mt_80 {
    margin-top: 80px;
}
.mt_85 {
    margin-top: 85px;
}
.mt_90 {
    margin-top: 90px;
}
.mt_95 {
    margin-top: 95px;
}
.mt_100 {
    margin-top: 100px;
}
.mt_105 {
    margin-top: 105px;
}
.mt_110 {
    margin-top: 110px;
}
.mt_115 {
    margin-top: 115px;
}
.mt_120 {
    margin-top: 120px;
}
.mt_125 {
    margin-top: 125px;
}
.mt_130 {
    margin-top: 130px;
}
.mt_135 {
    margin-top: 135px;
}
.mt_140 {
    margin-top: 140px;
}
.mt_145 {
    margin-top: 145px;
}
.mt_150 {
    margin-top: 150px;
}
.mt_155 {
    margin-top: 155px;
}
.mt_160 {
    margin-top: 160px;
}
.mt_165 {
    margin-top: 165px;
}
.mt_170 {
    margin-top: 170px;
}
.mt_175 {
    margin-top: 175px;
}
.mt_180 {
    margin-top: 180px;
}
.mt_185 {
    margin-top: 185px;
}
.mt_190 {
    margin-top: 190px;
}
.mt_195 {
    margin-top: 195px;
}
.mt_200 {
    margin-top: 200px;
}
/*-- Margin Bottom --*/
.mb_5 {
    margin-bottom: 5px;
}
.mb_10 {
    margin-bottom: 10px;
}
.mb_15 {
    margin-bottom: 15px;
}
.mb_20 {
    margin-bottom: 20px;
}
.mb_25 {
    margin-bottom: 25px;
}
.mb_30 {
    margin-bottom: 30px;
}
.mb_35 {
    margin-bottom: 35px;
}
.mb_40 {
    margin-bottom: 40px;
}
.mb_45 {
    margin-bottom: 45px;
}
.mb_50 {
    margin-bottom: 50px;
}
.mb_55 {
    margin-bottom: 55px;
}
.mb_60 {
    margin-bottom: 60px;
}
.mb_65 {
    margin-bottom: 65px;
}
.mb_70 {
    margin-bottom: 70px;
}
.mb_75 {
    margin-bottom: 75px;
}
.mb_80 {
    margin-bottom: 80px;
}
.mb_85 {
    margin-bottom: 85px;
}
.mb_90 {
    margin-bottom: 90px;
}
.mb_95 {
    margin-bottom: 95px;
}
.mb_100 {
    margin-bottom: 100px;
}
.mb_105 {
    margin-bottom: 105px;
}
.mb_110 {
    margin-bottom: 110px;
}
.mb_115 {
    margin-bottom: 115px;
}
.mb_120 {
    margin-bottom: 120px;
}
.mb_125 {
    margin-bottom: 125px;
}
.mb_130 {
    margin-bottom: 130px;
}
.mb_135 {
    margin-bottom: 135px;
}
.mb_140 {
    margin-bottom: 140px;
}
.mb_145 {
    margin-bottom: 145px;
}
.mb_150 {
    margin-bottom: 150px;
}
.mb_155 {
    margin-bottom: 155px;
}
.mb_160 {
    margin-bottom: 160px;
}
.mb_165 {
    margin-bottom: 165px;
}
.mb_170 {
    margin-bottom: 170px;
}
.mb_175 {
    margin-bottom: 175px;
}
.mb_180 {
    margin-bottom: 180px;
}
.mb_185 {
    margin-bottom: 185px;
}
.mb_190 {
    margin-bottom: 190px;
}
.mb_195 {
    margin-bottom: 195px;
}
.mb_200 {
    margin-bottom: 200px;
}
/*-- Padding Top --*/
.pt_5 {
    padding-top: 5px;
}
.pt_10 {
    padding-top: 10px;
}
.pt_15 {
    padding-top: 15px;
}
.pt_20 {
    padding-top: 20px;
}
.pt_25 {
    padding-top: 25px;
}
.pt_30 {
    padding-top: 30px;
}
.pt_35 {
    padding-top: 35px;
}
.pt_40 {
    padding-top: 40px;
}
.pt_45 {
    padding-top: 45px;
}
.pt_50 {
    padding-top: 50px;
}
.pt_55 {
    padding-top: 55px;
}
.pt_60 {
    padding-top: 60px;
}
.pt_65 {
    padding-top: 65px;
}
.pt_70 {
    padding-top: 70px;
}
.pt_75 {
    padding-top: 75px;
}
.pt_80 {
    padding-top: 80px;
}
.pt_85 {
    padding-top: 85px;
}
.pt_90 {
    padding-top: 90px;
}
.pt_95 {
    padding-top: 95px;
}
.pt_100 {
    padding-top: 100px;
}
.pt_105 {
    padding-top: 105px;
}
.pt_110 {
    padding-top: 110px;
}
.pt_115 {
    padding-top: 115px;
}
.pt_120 {
    padding-top: 120px;
}
.pt_125 {
    padding-top: 125px;
}
.pt_130 {
    padding-top: 130px;
}
.pt_135 {
    padding-top: 135px;
}
.pt_140 {
    padding-top: 140px;
}
.pt_145 {
    padding-top: 145px;
}
.pt_150 {
    padding-top: 150px;
}
.pt_155 {
    padding-top: 155px;
}
.pt_160 {
    padding-top: 160px;
}
.pt_165 {
    padding-top: 165px;
}
.pt_170 {
    padding-top: 170px;
}
.pt_175 {
    padding-top: 175px;
}
.pt_180 {
    padding-top: 180px;
}
.pt_185 {
    padding-top: 185px;
}
.pt_190 {
    padding-top: 190px;
}
.pt_195 {
    padding-top: 195px;
}
.pt_200 {
    padding-top: 200px;
}
.pt_230 {
    padding-top: 230px;
}
.pt_260 {
    padding-top: 260px;
}
.pt_280 {
    padding-top: 280px;
}
/*-- Padding Bottom --*/
.pb_5 {
    padding-bottom: 5px;
}
.pb_10 {
    padding-bottom: 10px;
}
.pb_15 {
    padding-bottom: 15px;
}
.pb_20 {
    padding-bottom: 20px;
}
.pb_25 {
    padding-bottom: 25px;
}
.pb_30 {
    padding-bottom: 30px;
}
.pb_35 {
    padding-bottom: 35px;
}
.pb_40 {
    padding-bottom: 40px;
}
.pb_45 {
    padding-bottom: 45px;
}
.pb_50 {
    padding-bottom: 50px;
}
.pb_55 {
    padding-bottom: 55px;
}
.pb_60 {
    padding-bottom: 60px;
}
.pb_65 {
    padding-bottom: 65px;
}
.pb_70 {
    padding-bottom: 70px;
}
.pb_75 {
    padding-bottom: 75px;
}
.pb_80 {
    padding-bottom: 80px;
}
.pb_85 {
    padding-bottom: 85px;
}
.pb_90 {
    padding-bottom: 90px;
}
.pb_95 {
    padding-bottom: 95px;
}
.pb_100 {
    padding-bottom: 100px;
}
.pb_105 {
    padding-bottom: 105px;
}
.pb_110 {
    padding-bottom: 110px;
}
.pb_115 {
    padding-bottom: 115px;
}
.pb_120 {
    padding-bottom: 120px;
}
.pb_125 {
    padding-bottom: 125px;
}
.pb_130 {
    padding-bottom: 130px;
}
.pb_135 {
    padding-bottom: 135px;
}
.pb_140 {
    padding-bottom: 140px;
}
.pb_145 {
    padding-bottom: 145px;
}
.pb_150 {
    padding-bottom: 150px;
}
.pb_155 {
    padding-bottom: 155px;
}
.pb_160 {
    padding-bottom: 160px;
}
.pb_165 {
    padding-bottom: 165px;
}
.pb_170 {
    padding-bottom: 170px;
}
.pb_175 {
    padding-bottom: 175px;
}
.pb_180 {
    padding-bottom: 180px;
}
.pb_185 {
    padding-bottom: 185px;
}
.pb_190 {
    padding-bottom: 190px;
}
.pb_195 {
    padding-bottom: 195px;
}
.pb_200 {
    padding-bottom: 200px;
}
/*-- Padding Left --*/
.pl_0 {
    padding-left: 0px;
}
.pl_5 {
    padding-left: 5px;
}
.pl_10 {
    padding-left: 10px;
}
.pl_15 {
    padding-left: 15px;
}
.pl_20 {
    padding-left: 20px;
}
.pl_25 {
    padding-left: 25px;
}
.pl_30 {
    padding-left: 30px;
}
.pl_35 {
    padding-left: 35px;
}
.pl_35 {
    padding-left: 35px;
}
.pl_40 {
    padding-left: 40px;
}
.pl_45 {
    padding-left: 45px;
}
.pl_50 {
    padding-left: 50px;
}
.pl_55 {
    padding-left: 55px;
}
.pl_60 {
    padding-left: 60px;
}
.pl_65 {
    padding-left: 65px;
}
.pl_70 {
    padding-left: 70px;
}
.pl_75 {
    padding-left: 75px;
}
.pl_80 {
    padding-left: 80px;
}
.pl_85 {
    padding-left: 80px;
}
.pl_90 {
    padding-left: 90px;
}
.pl_95 {
    padding-left: 95px;
}
.pl_100 {
    padding-left: 100px;
}
/*-- Padding Right --*/
.pr_0 {
    padding-right: 0px;
}
.pr_5 {
    padding-right: 5px;
}
.pr_10 {
    padding-right: 10px;
}
.pr_15 {
    padding-right: 15px;
}
.pr_20 {
    padding-right: 20px;
}
.pr_25 {
    padding-right: 35px;
}
.pr_30 {
    padding-right: 30px;
}
.pr_35 {
    padding-right: 35px;
}
.pr_35 {
    padding-right: 35px;
}
.pr_40 {
    padding-right: 40px;
}
.pr_45 {
    padding-right: 45px;
}
.pr_50 {
    padding-right: 50px;
}
.pr_55 {
    padding-right: 55px;
}
.pr_60 {
    padding-right: 60px;
}
.pr_65 {
    padding-right: 65px;
}
.pr_70 {
    padding-right: 70px;
}
.pr_75 {
    padding-right: 75px;
}
.pr_80 {
    padding-right: 80px;
}
.pr_85 {
    padding-right: 80px;
}
.pr_90 {
    padding-right: 90px;
}
.pr_95 {
    padding-right: 95px;
}
.pr_100 {
    padding-right: 100px;
}
/* font weight */
.f_700 {
    font-weight: 700;
}
.f_600 {
    font-weight: 600;
}
.f_500 {
    font-weight: 500;
}
.f_400 {
    font-weight: 400;
}
.f_300 {
    font-weight: 300;
}
/* Background Color */
.header_bg {
    background: #f9fbfc;
}
.gray_bg {
    background: #f7f7fd;
}
.soft_pink {
    background: #fffafa;
}
.white_bg {
    background: #fff;
}
.black_bg {
    background: #222;
}
.theme_bg {
    background: #222;
}
.primary_bg {
    background: #222;
}
.gn_bg {
    background-image: -moz-linear-gradient( 0deg, rgb(0, 0, 184) 0%, rgb(115, 0, 233) 100%);
    background-image: -webkit-linear-gradient( 0deg, rgb(0, 0, 184) 0%, rgb(115, 0, 233) 100%);
    background-image: -ms-linear-gradient( 0deg, rgb(0, 0, 184) 0%, rgb(115, 0, 233) 100%);
}
/* Color */
.white_color {
    color: #fff;
}
.black_color {
    color: #222;
}
.theme_color {
    color: #222;
}
.primary_color {
    color: #222;
}
/* black overlay */
[data-overlay] {
    position: relative;
}
[data-overlay]::before {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
[data-overlay="3"]::before {
    opacity: 0.3;
}
[data-overlay="4"]::before {
    opacity: 0.4;
}
[data-overlay="5"]::before {
    opacity: 0.5;
}
[data-overlay="6"]::before {
    opacity: 0.6;
}
[data-overlay="7"]::before {
    opacity: 0.7;
}
[data-overlay="8"]::before {
    opacity: 0.8;
}
[data-overlay="9"]::before {
    opacity: 0.9;
}
/* animation */
.rogan-hero-section.rogan-hero-three .shape-one {
    width: 990px;
    height: 990px;
    top: -108px;
    right: -184px;
    z-index: -1;
    border-radius: 50%;
    background: linear-gradient(134deg, #7be7ff, #5bffd2)
}
.rogan-hero-section.rogan-hero-three .shape-two {
    width: 70px;
    height: 70px;
    top: 16%;
    right: 770px;
    z-index: 1;
    background: linear-gradient(321deg, rgba(255, 56, 153, .36), rgba(255, 139, 64, .36));
    border-radius: 50%
}
.rogan-hero-section.rogan-hero-three .shape-three {
    width: 378px;
    height: 378px;
    bottom: 9%;
    right: 287px;
    z-index: 1;
    background: linear-gradient(321deg, rgba(250, 217, 97, .1), rgba(247, 107, 28, .1));
    border-radius: 50%
}
.rogan-hero-section.rogan-hero-three .shape-four {
    width: 12px;
    height: 12px;
    top: 20%;
    left: 28%;
    animation: animationFramesOne 25s infinite linear
}
.rogan-hero-section.rogan-hero-three .shape-five {
    width: 25px;
    height: 25px;
    top: 44%;
    left: 4%;
    animation: animationFramesOne 30s alternate infinite linear
}
.rogan-hero-section.rogan-hero-three .shape-six {
    width: 15px;
    height: 15px;
    top: 49%;
    left: 51%;
    animation: animationFramesTwo 35s infinite linear
}
.rogan-hero-section.rogan-hero-three .shape-seven {
    width: 12px;
    height: 12px;
    bottom: 20%;
    left: 33%;
    animation: animationFramesOne 30s infinite linear
}
.rogan-hero-section.rogan-hero-four {
    overflow: hidden
}