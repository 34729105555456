/*
Template Name: Incantation
Design By: Md Tanjim Ahmmed
*/
/*
css index
===========
*base css
*hero css
*menu navigate css
*time counter css
*Three easy step css
*changing life css
*footer css
*register css
*manage page css
*checkbox custom css
*switch css
*preloader css
*dark version home css
*hamburger css
*only home page hamburger css
*/
/* ============== base css ============ */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
*{
    margin: 0;
    padding: 0;
}
a:focus, input:focus, textarea:focus, button:focus{
    outline: 0 solid;
}
a:hover{
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6{
    margin:0 0 15px;
    font-weight:normal;
    font-style: normal;

}
p{
    font-size: 15px;
    font-weight: normal;
}
.pos_relative, .side_vector_bg, .home_section, .refresh_minutes {
    position: relative;
}
.fix{
    overflow: hidden;
}
body{
    font-weight: normal;
    font-style: normal;
    font-family: 'Open Sans', sans-serif;
}
.img, .banner_login img {
    max-width: 100%;
}
.btnc {
    border: medium none;
    border-radius: 5px;
    text-transform: uppercase;
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 0;
    padding: 17px 35px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    border: 1px solid transparent;
    color: #fff;
    background-color: #0FCCF5;
    box-shadow: 0px 9px 20px -5px #0fccf56e;
    font-weight: 700;

}
.btnc_border{
    background: transparent;
    border-color: #00c867;
    color: #1c3354;
}
.btnc_border:hover{
    border-color: transparent;
    color: #fff;
}

/*=========== hero css ============*/
.hero_quote_image img {
    width: 100%;
}
.logo_area h1, .header_area .logo_area a {
    color: #3ADBC9;
    text-transform: uppercase;
    font-size: 36px;
    display: block;
    font-weight: 700;
}
.hero_content h2 {
    font-size: 30px;
    color: #004A64;
    margin-bottom: 5px;
    font-weight: 700;
}
.hero_content {
    padding-top: 35px;
}
.hero_content p {
    font-size: 13.5px;
    color: #004A64;
    font-weight: 600;
}
.bg_shape, .bg_gray, .bg_gray_left {
    position: relative;
    z-index: 1;
}
.bg_shape::after {
    position: absolute;
    content: '';
    right: -10%;
    top: -100%;
    height: 1500px;
    background-image: url(../img/hero/hero-shape.png);
    background-repeat: no-repeat;
    z-index: -1;
    background-size: cover;
    width: 80%;
}

.hero_area.bg_shape {
    overflow: hidden;
}
.hero_content .btnc {
    margin-top: 160px;
}

.btnc:hover {
    color: #fff;
    box-shadow: 0px 9px 20px -5px #0fccf51a;
}
/* ================= menu navigate css =============== */
.bg_blue {
    background-color: #0FCCF5;
}
.single_items {
    text-align: center;
    position: relative;
}

.navigate_link h4 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 600;
}

.single_items:after {
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    content: '';
    height: 100%;
    width: 2px;
    display: block;
}
.single_items:last-child:after {
    display: none;
}

/* ====================== time counter css ====================== */
.bg_gray:after, .bg_gray_left:after, .change_life_content.bg_gray:after {
    position: absolute;
    content: '';
    width: 50%;
    height: 100%;
    left: 0;
    top: 0;
    background: #F5F2F2;
    z-index: -1;
}

.side_countdown_img img {
    max-width: 100%;
}

.single_box_content, .single_steps_box, .login_warp, .box_update_page {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 1px 1px 10px 0px #70707052;
    border-radius: 30px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.single_box_content h4 {
    color: #43DDB6;
    font-size: 16px;
}

.single_box_content p {
    margin-bottom: 0;
    color: #004A64;
    font-size: 15px;
    font-weight: 600;
}
.single_area_btn {
    text-align: center;
}
.bg_gray_left:after {
    left: auto;
    right: 0;
}

.bg_whit {
    background-color: #fff;
}

/* =========== Three easy step css ========== */

.header_text h2 {
    padding-bottom: 50px;
}
.steps_icon_right {
    text-align: right;
    margin-top: -30px;
}

.steps_icon_right span {
    font-size: 80px;
    color: #E6E6E6;
}

.steps_icon_left img {
    width: 100px;
}

.single_steps_box {
    padding: 30px;
    min-height: 336px;
}

.single_steps_icon {
    margin-bottom: 30px;
}

.single_steps_box h3 {
    font-size: 25px;
    color: #1998D3;
    margin-bottom: 5px;
}

.single_steps_box p {
    color: #004A64;
    font-size: 17px;
    font-weight: 600;
}

.single_steps_box:hover {
    box-shadow: 1px 1px 10px 0px #7070701f;
}

/* =============== changing life css ============== */
.change_life_content.bg_gray:after {
    width: 100%;
}

.change_life_content h2 {
    text-align: center;
    color: #1998D3;
}

.change_life_content p {
    font-size: 20px;
    color: #004A64;
}

/* =============== footer css ============== */

.gradiant_bg {
    background: rgb(103,232,141);
    background: -webkit-linear-gradient(45deg,  rgba(103,232,141,1) 0%,rgba(15,204,245,1) 100%);
    background: linear-gradient(45deg,  rgba(103,232,141,1) 0%,rgba(15,204,245,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#67e88d', endColorstr='#0fccf5',GradientType=1 );
}

.menu_footer ul, .main_menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.menu_footer ul li, .main_menu ul li {
    display: inline-block;
    text-align: right;
}

.menu_footer ul li a, .main_menu ul li a {
    color: #004A64;
    margin: 0 10px;
    font-weight: 600;
    font-size: 18px;
    display: block;

}
.menu_right {
    text-align: right;
}

/* ========== section for mobile css ========== */
.mobile_screen {
    display: none;
}

/* =================== register css ==================== */
.login_warp {
    height: 100%;
    width: 100%;
    display: table;
    border-radius: 5px;
}
.banner_login img {
    padding-top: 45px;
}
.login_wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.login_logo h3 {
    color: #3ADBC9;
    text-transform: uppercase;
    font-weight: 700;
    display: block;
    font-size: 30px;
    margin-bottom: 50px;
}


.login_form i.fa {
    position: absolute;
    left: 2%;
    z-index: 2;
    -webkit-transform: translate(22%, 0%);
    transform: translate(22%, 0%);
    background: transparent;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 5px 0 0 5px;
    color: #7070702b;
    font-size: 18px;
}
.login_form input {
    width: 100%;
    position: relative;
    height: 50px;
    padding: 10px;
    border: 1px solid #70707021;
    z-index: 1;
    margin-bottom: 15px;
    padding-left: 50px;
    border-radius: 50px;
}
.creat_ac input[type="submit"] {
    height: 40px;
    font-size: 18px;
    background: #0BCBFB;
    color: #fff;
    cursor: pointer;
    padding: 0;
}

.login_form input::-webkit-input-placeholder {
    color: #70707073;
    font-size: 18px;
}

.login_form input::-moz-placeholder {
    color: #70707073;
    font-size: 18px;
}

.login_form input:-ms-input-placeholder {
    color: #70707073;
    font-size: 18px;
}

.login_form input::placeholder {
    color: #70707073;
    font-size: 18px;
}
.login_form input[type='checkbox'] {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 58px;
    cursor: pointer;
    margin-top: 3px;
}

.login_form p {
    padding-left: 65px;
    color: #707070a6;
    font-size: 18px;
}

.creat_ac {
    text-align: right;
}
.creat_ac.forgot_ac, .night_btnc {
    text-align: center;
}
.creat_ac a {
    color: #70707073;
}
.login_logo.forgot_pas h3 {
    margin-bottom: 5px;
}

.login_logo.forgot_pas p {
    margin-bottom: 25px;
    color: #707070a6;
}

/* ================== manage page css ================ */

.header_area .logo_area h1, .header_area .logo_area a {
    color: #fff;
    padding-top: 0;
}

.main_menu ul li a {
    color: #fff;
    font-size: 20px;
    padding: 30px 10px;
    text-decoration: none;
}
.main_menu ul li {
    position: relative;
    z-index: 1;
}

.main_menu ul li:after {
    position: absolute;
    content: '';
    left: 10%;
    bottom: 25px;
    width: 0;
    height: 2px;
    background-color: #fff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.main_menu ul li:hover:after, .main_menu ul li.active:after {
    width: 80%;
}
.multiple_adding_line p {
    font-size: 18px;
    color: #004A64;
    font-weight: 600;
    padding-bottom: 25px;
}

.multiple_text_area textarea {
    width: 100%;
    resize: none;
    height: 130px;
    border-radius: 10px;
    border-color: #dddddd66;
    padding: 10px 10px;
    box-shadow: 0 0 6px 0px #ddddddc2;
    font-weight: 600;
}
.multiple_text_area.edit_page_area textarea {
    background: #EEEEEE;
}
.multiple_text_area textarea::-webkit-input-placeholder {
    color: #dadada;
}
.multiple_text_area textarea::-moz-placeholder {
    color: #dadada;
}
.multiple_text_area textarea:-ms-input-placeholder {
    color: #dadada;
}
.multiple_text_area textarea::placeholder {
    color: #dadada;
}
.multiple_text_area .btnc {
    margin-top: 10px;
    padding: 18px 65px;
    box-shadow: 0px 9px 20px -5px #0fccf52e;
}
.multiple_text_area .btnc.btne {
    text-transform: capitalize;
}
.multiple_text_area .btnc:hover {
    box-shadow: 0px 9px 20px -5px #0fccf526;
}

.btnc.btn_gray:hover {
    box-shadow: 0 0 3px 3px #dddddd3b;
    background-color: #FF0000;
}
.btnc.btn_gray {
    background-color: #D4D4D4;
    box-shadow: 0 0 4px 4px #dddddd24;
    padding: 18px 20px;
    text-transform: capitalize;
}

.adding_list_items {
    padding-top: 25px;
}

.adding_list_items ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.adding_list_items ul li {
    position: relative;
    z-index: 1;
    padding-left: 45px;
}
.header_gradient {
    background: rgb(143,252,160);
    background: -webkit-linear-gradient(left,  rgba(143,252,160,1) 0%,rgba(17,205,247,1) 100%);
    background: linear-gradient(to right,  rgba(143,252,160,1) 0%,rgba(17,205,247,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8ffca0', endColorstr='#11cdf7',GradientType=1 );
}
/* ========== checkbox custom css ========== */
.adding_list_items input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
    border: 4px solid #3ADBC9;
    position: absolute;
    left: 0;
}

.adding_list_items input[type='checkbox']:checked:after {display: block;}
.adding_list_items ul li label {
    font-size: 18px;
    font-weight: 600;
    color: #004A64;
    margin-right: 69px;
    overflow-wrap: anywhere;
    margin-left: -47px;

}
.adding_list_items ul li span {
    position: absolute;
    right: 0;
}

.adding_list_items ul li span .fontawesomeicon {
    margin-left: 20px;
    cursor: pointer;
    font-size: 20px;
    color: #dedede;
}
.adding_list_items ul li span .fontawesomeicon.color_edit_1 {
    color: #41A592;
}

.adding_list_items ul li span .fontawesomeicon.color_edit_2 {
    color: #FF0000;
}
.side_vector_bg:after, .side_vector_bg:before {
    position: absolute;
    left: -11%;
    top: 0;
    content: '';
    width: 24%;
    height: 79%;
    background-image: url(../img/hero/shape.png);
    background-size: cover;
    background-position: right;
    z-index: -1;
}

.side_vector_bg:before {
    left: auto;
    right: -28%;
    background-image: url(../img/hero/shape-2.png);
    width: 45%;
    top: auto;
    bottom: -25%;
    height: 625px;
    z-index: -1;
}

.shape-3, .shape-4, .shape-5 {
    background-image: url(../img/hero/shape-3.png);
    width: 230px;
    height: 230px;
    background-size: cover;
    position: absolute;
    left: -5%;
    z-index: -1;
}
.shape-4 {
    background-image: url(../img/hero/shape-5.png);
    right: 0;
    left: auto;
    bottom: 0;
    z-index: -1;
}
.shape-5 {
    background-image: url(../img/hero/shape-4.png);
    left: 25%;
    width: 150px;
    height: 150px;
    bottom: 0;
}
.side_vector_pb {
    padding-bottom: 300px;
}
.side_vector_bg {
    overflow: hidden;
}
/* ============== switch css ============= */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: #fff;
    box-shadow: 1px 2px 7px #ddd;
    border: 1px solid #fff;
}

.slider:before {
    position: absolute;
    content: "";
    height: 28px;
    width: 28px;
    left: 2px;
    bottom: 2px;
    background-color: #D4D4D4;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #fff;
    box-shadow: 1px 2px 7px #ddd;
    border: 1px solid #D4D4D4;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
    background: #3ADBC9;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.box_update_page .minit_area .btnc {
    padding: 13px 15px;
    font-size: 16px;
    box-shadow: 0px 9px 20px -5px #0fccf517;
    margin-left: 20px;
    background: #3ADBC9;
}
.box_update_page .minit_area.disabled_btn .btnc {
    background: #D4D4D4;
    box-shadow: none;
}
.box_update_page .minit_area.disabled_btn .btnc:hover {
    background: #d4d4d4e0;
}
.minit_area label, .box_update_page label {
    font-size: 18px;
    color: #004A64;
    font-weight: 600;
}

.border_bottom_cs {
    border-bottom: 1px solid #D4D4D4;
    padding-bottom: 10px;
}

.box_update_page label.refresh_minutes textarea {
    height: 35px;
    width: 70px;
    resize: none;
    text-align: center;
    border-radius: 5px;
    line-height: 30px;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
    position: absolute;
    left: 50%;
    top: -4px;
    border: 1px solid #fff;
    color: #000;
}
.box_update_page label.refresh_minutes span {
    margin-right: 90px;
}

.box_update_page label.refresh_minutes {
    margin-top: 8px;
}
.box_update_page label.refresh_minutes span:last-child {
    margin-right: 0;
    margin-left: 0;
}
.box_update_page {
    border-radius: 5px;
    background: #EEEEEE;
}
.switcher label {
    font-size: 20px;
    font-weight: 600;
}
/*================= preloader css ===============*/
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% {
          transform: scale(1.0);
          -webkit-transform: scale(1.0);
      }
}
/*===== custom loader css ====*/
.incantation_preloader_wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #0FCCF5;
    z-index: 9999;
}

.incantation_preloader_wrap .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 0;
    margin-left: -20px;
    margin-top: -20px;
}
.double-bounce1, .double-bounce2{
    background-color: #fff;
}

/* modal delate item css */
.center-mid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.modal-content.items_mt {
    margin-top: 300px;
}

.select-item-modal .modal-body {
    padding: 30px 0;
}

.select-item-modal .modal-body h4 {
    color: #004A64;
    font-size: 22px;
    margin-bottom: 30px;
}
.button_modal .btnc, .button_modal .btnc.btn_gray {
    padding: 10px 40px;
}

.button_modal .btnc {
    padding: 10px 55px;
    box-shadow: none;
    text-transform: capitalize;
}

.button_modal .btnc:hover {
    background: #0fccf5cc;
}

.button_modal .btnc.btn_gray:hover {
    background: #d4d4d4cc;
    box-shadow: none;
}
.button_modal .whit_border_notice h4 {
    background: #EEEEEE;
    display: inline-block;
    padding: 12px 20px;
    border-radius: 5px;
    font-size: 18px;
    color: #000;
    font-weight: 500;
}
.button_modal .whit_border_notice textarea {
    width: 100%;
    background: #EEEEEE;
    font-weight: 500;
    height: 50px;
    border: none;
    resize: none;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block;
}
/* =================== home css ================ */
.home_content_area h1 {
    color: #013648;
    font-size: 55px;
}

/* ================== dark version home css ================= */
.header_gradient.header_dark, .dark_body {
    background: #000;
    /*minHeight: '100vh'*/
    min-height: 100vh;

}
.home_content_area.color_white h1, .multiple_adding_line.color_white p, .multiple_adding_line.color_white .adding_list_items ul li label, .multiple_adding_line.color_white .adding_list_items input[type='checkbox']:after, .select-item-modal .modal-body .white_modal_text h4, .box_update_page.color_white label {
    color: #fff;
}

.header_gradient.header_dark_light {
    background: #1E1E1E;
}
.multiple_adding_line.color_white .adding_list_items input[type='checkbox'] {
    background-color: #000;
}
.multiple_adding_line.color_white .adding_list_items.white_theme input[type='checkbox']:after {
    color:#004A64;
}

.black_modal .modal-content, .box_update_page.black_modal {
    background: #1E1E1E;
}
.box_update_page.black_modal .border_bottom_cs {
    border-bottom: 1px solid #707070;
}
.box_update_page.black_modal .slider {
    box-shadow: none;
}
/* ============== hamburger css ============= */
.offcanvas_menu {
    display: none;
}

/* ============== only home page hamburger css ============= */
.offcanvas_menu.special_menu {
    display: block;
    position: fixed;
    top: 0;
    right: -26%;
    width: 26%;
    height: 100%;
    background: #1E1E1E;
    z-index: 99;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
    padding-top: 30px;
}
.offcanvas_menu.special_menu.active {
    right: 0;
}
.offcanvas_menu_overlay.special_home.active {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    visibility: visible;
}
.special_menu .menu_close {
    position: absolute;
    right: 0;
    width: 70px;
    height: 100%;
    top: 0;
}

.special_menu .menu_close i.fa {
    position: absolute;
    text-align: center;
    left: 0;
    width: 100%;
    top: 5%;
    font-size: 25px;
    cursor: pointer;
    color: #fff;
}

.menu_item_wrap.special_wrap {
    display: table;
    height: 100%;
    width: 100%;
}

.menu_item_inner.special_inner {
    display: table-cell;
    vertical-align: top;
    padding-top: 20px;
}

.offcanvas_menu.special_menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.offcanvas_menu.special_menu ul li a {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    display: block;
}

.offcanvas_menu.special_menu ul li {
    display: block;
    border-bottom: 2px solid #ffffff12;
    margin-bottom: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
}

.offcanvas_menu_overlay.special_home {
    background: #000;
    position: fixed;
    height: 100%;
    width: 100%;
    content: '';
    z-index: 3;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    visibility: hidden;
}

.menu_triggers.special_menu_triggers {
    display: inline-block;
    width: 35px;
    cursor: pointer;
    padding-top: 8px;
}

.menu_triggers.special_menu_triggers span {
    background: #12EEF8;
    height: 5px;
    display: block;
    border-radius: 5px;
}

.menu_triggers.special_menu_triggers span:nth-child(2) {
    margin: 6px 0;
}
.only_mobile_right {
    text-align: right;
}
/* ============= light hamburger menu css ============ */
.offcanvas_menu.light_background {
    background: #fff;
}

.offcanvas_menu.light_background .menu_item_inner ul li a {
    color: #004A64;
}

/*.offcanvas_menu.light_background .menu_close i.fa {
    color: #004A64;
}*/
.offcanvas_menu.light_background .menu_close .fatimes {
    color: #004A64;
}

.offcanvas_menu.light_background .menu_item_inner ul li {
    border-bottom: 1px solid #004a641a;
}

.z_index{
    z-index: 0;
}

.multiple_text_area .btnc:disabled{
    background-color: #D4D4D4;
}
