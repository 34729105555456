/*Laptop Layout: 1200px. */
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    .button_modal .whit_border_notice h4 {
        font-size: 16px;
    }
    .bg_shape:after {
        right: -3%;
        top: -100%;
        height: 1475px;
    }

}
/* Medium Layout: 992px. */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero_area.bg_shape .pt_150 {
        padding-top: 75px;
    }
    .bg_shape:after {
        height: 1310px;
        width: 75%;
    }
    .hero_content .btnc {
        margin-top: 50px;
    }
    .single_steps_box h3 {
        font-size: 20px;
    }
    .single_steps_box p {
        font-size: 16px;
    }
    .side_vector_bg:before {
        right: -40%;
        width: 60%;
    }
    .minit_area label, .box_update_page label {
        font-size: 15px;
    }
    .box_update_page .minit_area .btnc {
        font-size: 14px;
    }
    .home_content_area h1 {
        font-size: 45px;
    }
    .shape-3, .shape-4, .shape-5 {
        width: 180px;
        height: 180px;
    }
    .shape-5 {
        width: 120px;
        height: 120px;
    }
}
/* Tablet & mobile Layout: 768px. */
@media only screen and (max-width: 991px) {
    .hero_content h2 {
        font-size: 22px;
    }
    .btnc {
        font-size: 16px;
        padding: 13px 24px;
    }
    .hero_content .btnc {
        margin-top: 10px;
    }
    .hero_area.bg_shape .pt_150 {
        padding-top: 75px;
    }
    .bg_shape:after {
        height: 1120px;
    }
    .bg_gray:after {
        background: transparent;
    }
    .bg_gray_left:after {
        height: 100%;
        width: 100%;
    }
    .hero_content .btnc {
        margin-top: 50px;
    }
    .single_steps_box h3 {
        font-size: 20px;
    }
    .single_steps_box p {
        font-size: 16px;
    }
    .banner_login img {
        display: none;
    }
    .header_area .logo_area h1 {
        margin-bottom: 0;
    }
    .side_vector_bg:before {
        width: 51%;
        bottom: -19%;
        height: 429px;
    }
    .adding_list_items ul li label {
        font-size: 18px;
        padding-right: 63px;
        margin-right: 0px;
    }
    .side_vector_bg:after {
        left: -2%;
        width: 30%;
        height: 79%;
    }

    .mobile_screen {
        display: block;
        margin-bottom: 50px;
    }
    .big_screen {
        display: none;
    }
    .mobile_5 {
        margin-bottom: 5px;
    }
    .minit_area label, .box_update_page label {
        font-size: 15px;
    }
    .box_update_page .minit_area .btnc {
        font-size: 14px;
    }
    .shape-3, .shape-4, .shape-5 {
        width: 180px;
        height: 180px;
    }
    .shape-5 {
        width: 120px;
        height: 120px;
    }
    .home_content_area h1 {
        font-size: 33px;
    }
    .home_section.pt_50 {
        padding-top: 0;
    }
    .offcanvas_menu.special_menu {
        right: -30%;
        width: 30%;
    }
  /*   ============== main menu display none ============ */
    .main_menu {
        display: none;
    }
    .only_responsive_pd {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .offcanvas_menu {
        right: -60%;
        width: 60%;
    }

    /* ============== hamburger css ============= */
    .offcanvas_menu {
        display: block;
        position: fixed;
        top: 0;
        right: -35%;
        width: 35%;
        height: 100%;
        background: #1E1E1E;
        z-index: 99;
        -webkit-transition: 0.4s ease-in-out;
        transition: 0.4s ease-in-out;
        padding-top: 30px;
    }

    .menu_close {
        position: absolute;
        right: 0;
        width: 70px;
        height: 100%;
        top: 0;
    }

    .menu_close .fatimes {
        position: absolute;
        text-align: center;
        left: 0;
        width: 100%;
        top: 5%;
        font-size: 25px;
        cursor: pointer;
        color: #fff;
    }

    .menu_item_wrap {
        display: table;
        height: 100%;
        width: 100%;
    }

    .menu_item_inner {
        display: table-cell;
        vertical-align: top;
        padding-top: 20px;
    }

    .offcanvas_menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .offcanvas_menu ul li a {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        display: block;
    }

    .offcanvas_menu ul li {
        display: block;
        border-bottom: 2px solid #ffffff12;
        margin-bottom: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
    }

    .offcanvas_menu_overlay {
        background: #000;
        position: fixed;
        height: 100%;
        width: 100%;
        content: '';
        z-index: 3;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        visibility: hidden;
    }

    .menu_triggers {
        display: inline-block;
        width: 35px;
        cursor: pointer;
        padding-top: 8px;
    }

    .menu_triggers span {
        background: #12EEF8;
        height: 5px;
        display: block;
        border-radius: 5px;
    }

    .menu_triggers span:nth-child(2) {
        margin: 6px 0;
    }
    .only_mobile_right {
        text-align: right;
    }
    .offcanvas_menu.active {
        right: 0;
    }
    .offcanvas_menu_overlay.active {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        visibility: visible;
    }
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {


}
/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
    .hero_content h2 {
        font-size: 22px;
    }

    .logo_area h1, .header_area .logo_area a {
        font-size: 20px;
    }
    .btnc {
        font-size: 16px;
        padding: 13px 24px;
    }
    .hero_content .btnc {
        margin-top: 10px;
    }
    .hero_area.bg_shape .pt_150 {
        padding-top: 0;
    }
    .bg_shape:after {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
        top: -30%;
        width: 72%;
        background-size: cover;
        right: -25%;
        height: 698px;
    }
    .hero_content p {
        font-size: 13px;
    }
    .bg_gray:after {
        background: transparent;
    }
    .bg_gray_left:after {
        height: 100%;
        width: 100%;
    }
    .hero_content .btnc {
        margin-top: 25px;
    }
    .hero_quote_image {
        padding-top: 50px;
    }
    .hero_area.bg_shape {
        padding-bottom: 50px;
    }
    .navigate_link h4 {
        font-size: 15px;
    }
    .mobile_screen {
        display: block;
        margin-bottom: 50px;
    }
    .big_screen {
        display: none;
    }
    .header_text h2, .change_life_content h2 {
        font-size: 24px;
    }
    .steps_icon_left img {
        width: 100px;
    }
    .steps_icon_right span {
        font-size: 70px;
        color: #E6E6E6;
    }
    .single_steps_box h3 {
        font-size: 20px;
    }
    .single_steps_box p {
        font-size: 16px;
    }
    .change_life_content {
        padding: 20px;
    }

    .change_life_content p {
        font-size: 15px;
    }
    .menu_footer ul li a {
        font-size: 16px;
    }
    .menu_right {
        text-align: center;
    }
    .login_form p {
        font-size: 14px;
    }
    .login_form input[type='checkbox'] {
        width: 15px;
        height: 15px;
    }
    .header_area .logo_area h1 {
        margin-bottom: 0;
        padding-top: 25px;
    }
    .btnc, .multiple_text_area .btnc {
        font-size: 13px;
        padding: 13px 24px;
    }
    .adding_list_items ul li label {
        font-size: 15px;
        padding-right: 63px;

    }
    .side_vector_bg:after, .side_vector_bg:before {
        display: none;
    }
    .minit_area label, .box_update_page label {
        font-size: 14px;
    }
    .box_update_page .text-right {
        text-align: left !important;
    }
    .box_update_page .minit_area .btnc {
        padding: 13px 15px;
        margin-left: 0;
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }
    .box_update_page .minit_area label {
        width: 100%;
    }
    .mobile_5 {
        margin-bottom: 5px;
    }
    .select-item-modal .modal-body h4 {
        font-size: 16px;
    }
    .modal-content.items_mt {
        margin-top: 265px;
    }
    .shape-3, .shape-4, .shape-5 {
        width: 80px;
        height: 80px;
    }
    .shape-4 {
        bottom: 85px;
    }
    .shape-5 {
        width: 65px;
        height: 65px;
    }
    .home_content_area h1 {
        font-size: 17px;
    }
    .home_section.pt_50 {
        padding-top: 0;
    }
    .home_content_area.pt_150 {
        padding-top: 85px;
    }
    .offcanvas_menu.special_menu {
        right: -65%;
        width: 65%;
    }
    /* ============== main menu display none ============ */
    .main_menu {
        display: none;
    }
    .only_responsive_pd {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .offcanvas_menu {
        right: -60%;
        width: 60%;
    }

    /* ============== hamburger css ============= */
    .offcanvas_menu {
        display: block;
        position: fixed;
        top: 0;
        right: -65%;
        width: 65%;
        height: 100%;
        background: #1E1E1E;
        z-index: 99;
        -webkit-transition: 0.4s ease-in-out;
        transition: 0.4s ease-in-out;
        padding-top: 30px;
    }

    .menu_close {
        position: absolute;
        right: 0;
        width: 70px;
        height: 100%;
        top: 0;
    }

    .menu_close .fatimes {
        position: absolute;
        text-align: center;
        left: 0;
        width: 100%;
        top: 5%;
        font-size: 25px;
        cursor: pointer;
        color: #fff;
    }

    .menu_item_wrap {
        display: table;
        height: 100%;
        width: 100%;
    }

    .menu_item_inner {
        display: table-cell;
        vertical-align: top;
        padding-top: 20px;
    }

    .offcanvas_menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .offcanvas_menu ul li a {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        display: block;
    }

    .offcanvas_menu ul li {
        display: block;
        border-bottom: 2px solid #ffffff12;
        margin-bottom: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
    }

    .offcanvas_menu_overlay {
        background: #000;
        position: fixed;
        height: 100%;
        width: 100%;
        content: '';
        z-index: 3;
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        visibility: hidden;
    }

    .menu_triggers {
        display: inline-block;
        width: 35px;
        cursor: pointer;
        padding-top: 8px;
    }

    .menu_triggers span {
        background: #12EEF8;
        height: 5px;
        display: block;
        border-radius: 5px;
    }

    .menu_triggers span:nth-child(2) {
        margin: 6px 0;
    }
    .only_mobile_right {
        text-align: right;
    }
    .offcanvas_menu.active {
        right: 0;
    }
    .offcanvas_menu_overlay.active {
        opacity: 0.5;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
        visibility: visible;
    }
    .box_update_page .minit_area label {
        width: 100%;
    }
    .box_update_page label.refresh_minutes {
        margin-bottom: 25px;
    }
    .box_update_page label.refresh_minutes span {
        margin-right: 65px;
    }
    .box_update_page label.refresh_minutes span:last-child {
        margin-left: 5px;
    }
    .box_update_page label.refresh_minutes textarea {
        width: 50px;
        margin-left: 15px;
    }

}
/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}